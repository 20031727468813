<template>
  <Loader v-if="!clients" />
  <!-- Begin Page Content -->
  <div v-else class="container-fluid">
    <!--
    <div class="project-head d-flex">
      <div class="editable-head">
        <h1 class="h3 mb-0 text-gray-800">Клиенты</h1>
        <p class="mb-4 d-block">Доступные клиенты</p>
      </div>
    </div>
-->
    <!-- Begin Page Content -->

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card shadow mb-4">
            <!-- Card Header - Accordion -->
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Новые клиенты</h6>
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLinkDetails" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in d-print-none" aria-labelledby="dropdownMenuLinkDetails">
                  <div class="dropdown-header">Доступные действия:</div>
                  <button v-on:click="Print" class="dropdown-item">Печать</button>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#collapseCardExample" data-toggle="collapse">Свернуть/Развернуть</a>
                </div>
              </div>
            </div>
            <!-- Card Content - Collapse -->
            <div class="collapse show" id="collapseCardExample">
              <div class="card-body">
                <table class="table table-hover table-sm table-bordered text-center">
                  <thead>
                    <tr>
                      <th scope="col">ФИО</th>
                      <th scope="col">Сумма</th>
                      <th scope="col">Действия</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="client in clients" :key="client.id">
                      <th scope="row">{{ client.lastname }} {{ client.firstname }} {{ client.patronymic }}</th>
                      <th> {{client.product.amount}} </th>
                      <th>Взять</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>

</template>
<script>
import Loader from "../../components/static/Loader";
import axios from "axios";

export default {
  name: "NewClientsPage",
  data(){
    return{
      clients:null,
    }
  },
  mounted() {
    this.$store.commit('SET_LOADER_VISIBLE',false);
    this.getClients();
  },
  components: {Loader},
  methods:{
    getClients: async function(){
      let res = await axios.post('clients/new');
      this.clients = res.data.clients;
    }
  },
}
</script>
<style scoped></style>
